import {
  Box,
  Button,
  Grid,
  InputAdornment,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { BoxValue, IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
// import SellIcon from "@mui/icons-material/Sell";
// import PersonOffIcon from "@mui/icons-material/PersonOff";
import { ReactNode, useEffect, useState } from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
// import BoltIcon from "@mui/icons-material/Bolt";
import { useUpdateEvse } from "../../../services/evse";

export const ModalReservation = (props: {
  open: boolean;
  onClose: () => void;
  init?: IChargePoint;
  success: () => void;
}) => {
  const [evse, setEvse] = useState<Partial<IEvseChargePoint>>();

  useEffect(() => {
    setEvse(props.init?.Evse?.[0]);
  }, [props.open, props.init?._id]);

  const { mutateAsync } = useUpdateEvse();

  const handleUpdate = async () => {
    try {
      const result = await mutateAsync({
        id: props.init?.Evse?.[0]._id!,
        dto: { ...evse },
      });
      if (result) {
        props.success();
        props.onClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Modal
      {...props}
      name="Reservation+"
      description="Customize and control charging station reservations"
      icon={<IconApp width={"30px"} name={"Cash"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
          <Grid item xs={3} borderRight="1px solid rgba(255, 255, 255, 0.12)">
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Model
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {evse?.model}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Type
            </Text>
            <Box display={"flex"} gap={2} alignItems="center">
              <IconApp
                name={evse?.current_type === "ac" ? "AcCharger" : "DcCharger"}
                width="25px"
              />
              <Typography
                sx={{
                  fontSize: { xs: "17px" },
                }}
              >
                {evse?.max_p} kW
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {props.init?.Location?.[0]?.name}
            </Typography>
          </Grid>
          <Grid item xs={3} pl={1}>
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Charge point ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {props.init?.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection={"column"}
            gap={3}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box>
                <Typography fontSize={"14px"}>
                  Idling penalty function
                </Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Toggle to activate or deactive function)
                </Typography>
              </Box>
              <Switch
                checked={evse?.idle_timing_enabled}
                onChange={(e) =>
                  setEvse({ ...evse, idle_timing_enabled: e.target.checked })
                }
              />
            </Box>
            <Box>
              <Box>
                <Typography fontSize={"14px"}>Grace Period</Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Time allowance for late-comers)
                </Typography>
              </Box>
              <Box display={"flex"} gap={1} mt={1}>
                {["15", "30"].map((item) => (
                  <BoxValue
                    active={evse?.idle_grace_period === item}
                    onClick={() =>
                      setEvse({ ...evse, idle_grace_period: item })
                    }
                  >
                    <Typography fontSize={"20px"}>
                      {item} <span>mins</span>
                    </Typography>
                  </BoxValue>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection={"column"}
            gap={3}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box>
                <Typography fontSize={"14px"}>Same day reservation</Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Toggle to activate or deactive function)
                </Typography>
              </Box>
              <Switch
                checked={evse?.reservation_same_day}
                onChange={(e) =>
                  setEvse({ ...evse, reservation_same_day: e.target.checked })
                }
              />
            </Box>
            <Box>
              <Box>
                <Typography fontSize={"14px"}>
                  Cancellation grace period
                </Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Allow user to cancel booking w/o penaty)
                </Typography>
              </Box>
              <Box display={"flex"} gap={1} mt={1}>
                {["60", "90"].map((item) => (
                  <BoxValue
                    active={evse?.reservation_cancel_grace_period === item}
                    onClick={() =>
                      setEvse({
                        ...evse,
                        reservation_cancel_grace_period: item,
                      })
                    }
                  >
                    <Typography fontSize={"20px"}>
                      {item} <span>mins</span>
                    </Typography>
                  </BoxValue>
                ))}
                {/* <BoxValue */}
                {/*   active={evse?.reservation_block === "90"} */}
                {/*   // onClick={() => setEvse({ ...evse, idle_grace_period: "30" })} */}
                {/* > */}
                {/*   <Typography fontSize={"20px"}> */}
                {/*     90 <span>mins</span> */}
                {/*   </Typography> */}
                {/* </BoxValue> */}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box>
          <Typography fontSize={"14px"}>Reservation period</Typography>
          <Typography fontSize={"10px"} color="#9B9B9B">
            (Time period for each reservation)
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {Array.from({ length: 12 }, (_, i) => (i + 1) * 30).map((item) => (
            <Grid item xs={4} md={3}>
              <BoxValue
                active={evse?.reservation_block === `${item}`}
                onClick={() =>
                  setEvse({ ...evse, reservation_block: `${item}` })
                }
              >
                <Typography
                  fontSize={"20px"}
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="end"
                >
                  {item} <span>mins</span>
                </Typography>
              </BoxValue>
            </Grid>
          ))}
        </Grid>

        <Box>
          <Typography fontSize={"14px"}>Reservation deposit</Typography>
          <Typography fontSize={"10px"} color="#9B9B9B">
            (Amount pre-auth upon booking)
          </Typography>
        </Box>

        <Box display={"flex"} alignItems="center" gap={2}>
          <Typography fontSize={"15px"}>Current price</Typography>
          <Typography fontSize={"15px"}>
            {props.init?.Evse?.[0]?.reservation_deposit_amount || 0} S$
          </Typography>
        </Box>

        <Box display={"flex"} gap={1} alignItems="center">
          <PriorityHighIcon fontSize="small" />
          <Typography fontSize={"10px"}>
            To make changes to electricity tariff, please contact your
            relationship offcier
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
              onClick={handleUpdate}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="inherit"
              onClick={props.onClose}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });
