import { HttpClient, URL_API } from "../../network";

export type IEvse = {
  _id: string;
  ampeco_evse_id: string;
  CPO: {
    _id: string;
    name: string;
  };
  ChargePoint: {
    _id: string;
    name: string;
  }[];
  Location: { name: string; _id: string };
  model: string;
  max_a: number;
  max_p: number;
  max_v: number;
  connectors: string;
  short_code: string;
  price_normal: number;
  price_vip: number;
  price_subscription: number;
  idle_grace_fee: number;
  idle_grace_period: number;
  idle_timing_enabled: boolean;
  reservation_cancel_grace_period: number;
  reservation_grace_period: number;
  reservation_block: number;
  reservation_deposit_amount: number;
  reservation_same_day: boolean;
  reservation_enabled: boolean;
  SeedingPartner: { _id: string; name: string };
  active_subscription: boolean;
  createdAt: string;
  current_type: string;
  hardware_status: string;
  is_enabled: boolean;
  network_id: number;
  status: string;
  updatedAt: string;
};

class Evse extends HttpClient {
  getAll = async (params: TParams) => {
    const data = await this.instance.get<IResponse<Array<IEvse>>>(
      URL_API.EVBS.GET_LIST,
      {
        params,
      },
    );
    return data;
  };
  getDetail = async (id: string) => {
    const data = await this.instance.get<any>(URL_API.EVBS.GET_LIST + `/${id}`);
    return data;
  };

  update = async (id: string, params: Object) => {
    const data = await this.instance.put<any>(
      URL_API.EVBS.GET_LIST + `/${id}`,
      params,
    );
    return data;
  };

  listUser = async (params?: Object) => {
    const data = await this.instance.get<any>(
      URL_API.EVBS.GET_LIST + `/restricted-users`,
      { params },
    );
    return data;
  };

  addRestrictedUser = async (dto: { users: string[]; evses: string[] }) => {
    const data = await this.instance.post(
      URL_API.EVBS.GET_LIST + `/add-restricted-user`,
      dto,
    );
    return data;
  };

  topUtilizationRating = async (params: TParams) => {
    const data = await this.instance.get<IResponse<Array<IEvse>>>(
      URL_API.EVBS.TOP_UTILIZATION_RATING,
      {
        params,
      },
    );
    return data;
  };
}
export const EvseService = new Evse();
