import {
  Box,
  Button,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import SellIcon from "@mui/icons-material/Sell";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { ReactNode, useState } from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BoltIcon from "@mui/icons-material/Bolt";
import { useUpdateEvse } from "../../../services/evse";

export const ModalPriceAdjustment = (props: {
  open: boolean;
  onClose: () => void;
  init?: IChargePoint;
  success: () => void;
}) => {
  const { mutateAsync } = useUpdateEvse();
  const [valueUpdate, setValueUpdate] = useState<{
    price_normal: number;
    price_vip: number;
  }>({ price_vip: 0, price_normal: 0 });

  const handleUpdate = async () => {
    try {
      const result = await mutateAsync({
        id: props.init?.Evse?.[0]._id!,
        dto: { ...props.init?.Evse?.[0], ...valueUpdate },
      });
      if (result) {
        props.success();
        props.onClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Modal
      {...props}
      name="Price adjustment"
      description="View & edit your charge point’s pricing"
      icon={<IconApp width={"30px"} name={"Cash"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
          <Grid item xs={3} borderRight="1px solid rgba(255, 255, 255, 0.12)">
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Model
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {props.init?.Evse?.[0]?.model || ""}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Type
            </Text>
            <Box display={"flex"} gap={2} alignItems="center">
              <IconApp
                name={
                  props.init?.Evse?.[0]?.current_type === "ac"
                    ? "AcCharger"
                    : "DcCharger"
                }
                width="25px"
              />
              <Typography
                sx={{
                  fontSize: { xs: "17px" },
                }}
              >
                {props.init?.Evse?.[0]?.max_p || 0} kW
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {props.init?.Location?.[0]?.name}
            </Typography>
          </Grid>
          <Grid item xs={3} pl={1}>
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Charge point ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {props.init?.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <BoxPrice
            icon={<SellIcon />}
            name="Public pricing"
            des="(Applies to unrestricted users)"
            callback={(e) => {
              setValueUpdate({ ...valueUpdate, price_normal: e });
            }}
            currentPrice={props.init?.Evse?.[0]?.price_normal || 0}
          />
          <BoxPrice
            icon={<PersonOffIcon />}
            name="Restricted pricing"
            des="(Applies to restricted users)"
            callback={(e) => {
              setValueUpdate({ ...valueUpdate, price_vip: e });
            }}
            currentPrice={props.init?.Evse?.[0]?.price_vip || 0}
          />
        </Grid>

        <Box display={"flex"} gap={0.5} alignItems="center">
          <BoltIcon fontSize="medium" />
          <Typography fontSize={"14px"}>
            Electricity tariff rate
            <span>(Cost of electricity)</span>
          </Typography>
        </Box>

        <Box display={"flex"} alignItems="center" gap={2}>
          <Typography fontSize={"15px"}>Current price</Typography>
          <Typography fontSize={"15px"}>
            {props.init?.Evse?.[0]?.tariff || 0} S$
          </Typography>
        </Box>

        <Box display={"flex"} gap={1} alignItems="center">
          <PriorityHighIcon fontSize="small" />
          <Typography fontSize={"10px"}>
            To make changes to electricity tariff, please contact your
            relationship offcier
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
              onClick={handleUpdate}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={props.onClose}
              color="inherit"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });

const BoxPrice = (props: {
  name: string;
  icon: ReactNode;
  des: string;
  currentPrice: number;
  callback: (e: number) => void;
}) => {
  return (
    <Grid item xs={12} md={6} display="flex" flexDirection={"column"} gap={1}>
      <Box display={"flex"} gap={1} alignItems="center">
        {props.icon}
        <Typography fontSize={"14px"}>
          {props.name} <span style={{ fontSize: "10px" }}>{props.des}</span>
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography fontSize={"15px"}>Current price</Typography>
        <Typography fontSize={"15px"}>{props.currentPrice} S$</Typography>
      </Box>
      <Box>
        <Typography fontSize={"15px"} mb={1}>
          New
        </Typography>
        <TextField
          fullWidth
          // size="small"
          autoComplete="off"
          placeholder="Input your values"
          InputProps={{
            endAdornment: <InputAdornment position="end">S$</InputAdornment>,
          }}
          type="number"
          onChange={(e) => props.callback(+e.target.value)}
        />
      </Box>
    </Grid>
  );
};
