import {
  Autocomplete,
  Box,
  Button,
  Grid,
  styled,
  // Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { ColumnTitle, IconApp, Modal, Pagination } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import BoltIcon from "@mui/icons-material/Bolt";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useInfiniteQuery } from "react-query";
import { useMemo, useState } from "react";
import { UserService } from "../../../services/user/user.service";
import {
  useAddRestrictedUserEvse,
  useListUserEvse,
} from "../../../services/evse";
import { useDebounce } from "../../../hooks/useDebounce";

export const ModalRestrictedUser = (props: {
  open: boolean;
  onClose: () => void;
  init?: IChargePoint;
}) => {
  const evse = props.init?.Evse?.[0];
  const [users, setUsers] = useState<string[]>([]);
  const { value, setValue } = useDebounce({ initialValue: "", delay: 300 });
  const [page, setPage] = useState(1);
  const { mutateAsync } = useAddRestrictedUserEvse();
  const { data: listUserEvse, refetch } = useListUserEvse({
    page,
    per_page: 5,
    evse_id: evse?._id,
  });

  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["infinity_user", value],
    enabled: props.open && value?.trim() !== "",
    queryFn: ({ pageParam = 1 }) => fetchListUser({ pageParam, phone: value }),
    getNextPageParam: (currentPage, allPage) => {
      const check =
        (currentPage?.per_page || 0) * (currentPage?.page || 0) <
        (currentPage?.total || 0);
      return check ? allPage.length + 1 : undefined;
    },
  });

  const listUser: Array<{ name: string; _id: string; phone: number }> =
    useMemo(() => {
      const arr = data?.pages.map((page) => {
        return (
          page?.data.map((item: any) => ({
            _id: item._id,
            name: item.name || "Unknown",
            phone: item.phone || "Unknown",
          })) || []
        );
      });
      return arr?.flat() || [];
    }, [data?.pages]);

  const handleUpdate = async () => {
    if (users.length !== 0 && evse)
      try {
        const result = await mutateAsync({
          dto: { users: users, evses: [evse._id!] },
        });
        if (result) {
          refetch();
        }
      } catch (error) {
        console.log("error", error);
      }
  };

  return (
    <Modal
      {...props}
      name="Restricted Users"
      description="View & edit your charge point’s pricing"
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
          <Grid item xs={3} borderRight="1px solid rgba(255, 255, 255, 0.12)">
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Model
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {evse?.model}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Type
            </Text>
            <Box display={"flex"} gap={2} alignItems="center">
              <IconApp
                name={evse?.current_type === "ac" ? "AcCharger" : "DcCharger"}
                width="25px"
              />
              <Typography
                sx={{
                  fontSize: { xs: "17px" },
                }}
              >
                {evse?.max_p} kW
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {props.init?.Location?.[0].name}
            </Typography>
          </Grid>
          <Grid item xs={3} pl={1}>
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Charge point ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              {props.init?.name}
            </Typography>
          </Grid>
        </Grid>

        <Box display={"flex"} flexDirection="column" gap={1}>
          <TableContainer>
            <Scrollbars
              autoHeight
              autoHeightMin="100%"
              autoHeightMax="100%"
              style={{ width: "100%" }}
              autoHide
            >
              <Table sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow>
                    <ColumnTitle align="center" sx={{ minWidth: 70 }}>
                      SN
                    </ColumnTitle>
                    <ColumnTitle>User</ColumnTitle>
                    <ColumnTitle>Registered mobile no</ColumnTitle>
                    <ColumnTitle>Email</ColumnTitle>
                    {/* <ColumnTitle>Status</ColumnTitle> */}
                    {/* <ColumnTitle>Joined date</ColumnTitle> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listUserEvse?.data.map((item: any, index: number) => (
                    <TableRow>
                      <TableCell align="center">
                        {page * 5 - 5 + index + 1}
                      </TableCell>
                      <TableCell>{item.user.name}</TableCell>
                      <TableCell>{item.user.phone}</TableCell>
                      <TableCell>{item.user.email}</TableCell>
                      {/* <TableCell> */}
                      {/*   <Chip */}
                      {/*     color="error" */}
                      {/*     label="Disable" */}
                      {/*     style={{ width: "100%" }} */}
                      {/*   /> */}
                      {/* </TableCell> */}
                      {/* <TableCell>Tue Mar 28 2023 15:52:19 </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbars>
          </TableContainer>
          <Pagination
            page={page}
            setPage={setPage}
            totalItems={listUserEvse?.total || 0}
            totalPage={Math.ceil((listUserEvse?.total || 0) / 5)}
            currentPage={page}
          />
        </Box>

        <Box display={"flex"} flexDirection="column" gap="15px">
          <Box display={"flex"} gap={1} alignItems="center">
            <BoltIcon fontSize="medium" />
            <Typography fontSize={"14px"}>
              Add restricted users {/* <span style={{ color: "#9B9B9B" }}> */}
              {/*   Enter the user’s mobile numberin each line to add him/ her as a */}
              {/*   “RU” */}
              {/* </span> */}
            </Typography>
          </Box>
          <Box>
            <Typography>Your mobile number</Typography>
            <Autocomplete
              onChange={(_, value) => {
                setUsers(value.map((item) => item._id));
              }}
              multiple
              limitTags={5}
              disableCloseOnSelect
              options={listUser}
              getOptionLabel={(option) => `${option.name} - ${option.phone}`}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    hasNextPage && fetchNextPage();
                  }
                },
              }}
              onInputChange={(_, e) => setValue(e)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Users" />
              )}
              fullWidth
            />
          </Box>
          {/* <Box display={"flex"} gap={1} alignItems="center"> */}
          {/*   <PersonAddAltIcon fontSize="medium" /> */}
          {/*   <Typography fontSize={"14px"}> */}
          {/*     Add the user to ALL charger in the location */}
          {/*   </Typography> */}
          {/*   <Switch defaultChecked /> */}
          {/* </Box> */}

          <Box display={"flex"} gap={1} alignItems="center">
            <PriorityHighIcon fontSize="small" />
            <Typography fontSize={"10px"}>
              To make changes to electricity tariff, please contact your
              relationship offcier
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
              onClick={handleUpdate}
              disabled={!evse || users.length === 0}
            >
              Add more user
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="inherit"
              onClick={props.onClose}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });

const fetchListUser = async ({
  pageParam,
  phone,
}: {
  pageParam: number;
  phone?: string;
}) => {
  try {
    const { data } = await UserService.getListUser({
      page: pageParam,
      per_page: 20,
      phone,
    });
    return data;
  } catch (error) {
    console.log("error", error);
  }
};
