import { useMutation, useQuery } from "react-query";
import { EvseService } from "./evse.service";

export const useEvse = (params: TParams) => {
  return useQuery(
    ["list_evbs", params],
    async () => {
      const { data } = await EvseService.getAll(params);
      return data;
    },
    { keepPreviousData: true },
  );
};

export const useDetailEvse = (id?: string) => {
  return useQuery(
    [id],
    async () => {
      const { data } = await EvseService.getDetail(id!);
      return data;
    },
    {
      enabled: id ? true : false,
    },
  );
};

export const useTopUtilizationRatingEvse = (params: TParams) =>
  useQuery({
    queryKey: ["top-utilization-rating-evse"],
    queryFn: async () => {
      try {
        const { data } = await EvseService.topUtilizationRating(params);
        return data;
      } catch (error) {
        console.log("error", error);
      }
    },
  });

export const useListUserEvse = (params?: Object) =>
  useQuery({
    queryKey: ["list_users_evse", params],
    queryFn: async () => {
      try {
        const { data } = await EvseService.listUser(params);
        return data;
      } catch (error) {
        console.log("error", error);
      }
    },
  });

export const useUpdateEvse = () =>
  useMutation({
    mutationFn: async ({ id, dto }: { id: string; dto: Object }) => {
      try {
        const { data } = await EvseService.update(id, dto);
        return data;
      } catch (error) {
        console.log("error", error);
      }
    },
  });

export const useAddRestrictedUserEvse = () =>
  useMutation({
    mutationFn: async ({
      dto,
    }: {
      dto: { users: string[]; evses: string[] };
    }) => {
      try {
        const { data } = await EvseService.addRestrictedUser(dto);
        return data;
      } catch (error) {
        console.log("error", error);
      }
    },
  });
