import {
  Autocomplete,
  Box,
  Checkbox,
  TableCell,
  TableCellProps,
  TableRow,
  TextField,
} from "@mui/material";
import { memo, PropsWithChildren, useEffect } from "react";
import { useDebounce } from "../../hooks/useDebounce";

export const ColumnTitle = memo(
  (
    props: PropsWithChildren &
      TableCellProps & {
        filter?: boolean;
        filterOption?: Array<{
          label: string;
          value: string | number | boolean;
        }>;
        filterDate?: boolean;
        onChangeText?: (arg?: string) => void;
        disable?: boolean;
      },
  ) => {
    const { value, setValue } = useDebounce({ initialValue: "", delay: 300 });

    useEffect(() => {
      props?.onChangeText?.(value.trim() === "" ? undefined : value.trim());
    }, [value]);

    return (
      <TableCell
        sx={{
          minWidth: 150,
        }}
        {...props}
      >
        <Box minWidth={"max-content"} {...props}>
          {props.children}
        </Box>
        {props.filter && (
          <TextField
            size="small"
            disabled={props?.disable}
            placeholder="Type to Filter"
            autoComplete="off"
            fullWidth
            onChange={(e) => setValue(e.target.value)}
          />
        )}

        {props.filterOption && (
          <Autocomplete
            options={props.filterOption || []}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(_, e) => {
              props?.onChangeText?.(e?.value as any);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select option" size="small" />
            )}
            fullWidth
          />
        )}
      </TableCell>
    );
  },
);

export const ColumnSelect = memo(
  (
    props: PropsWithChildren &
      TableCellProps & { selectAll: boolean; onCallback: (e: boolean) => void },
  ) => {
    return (
      <TableCell width={70} align="center" {...props}>
        <Box {...props}>{props.children}</Box>
        <Checkbox
          checked={props.selectAll}
          onChange={(e) => props.onCallback(e.target.checked)}
        />
      </TableCell>
    );
  },
);

export const NoDataTable = ({ numberOfCol }: { numberOfCol: number }) => {
  return (
    <TableRow>
      {Array.from({ length: numberOfCol }, (_, index) => {
        if (index === 0)
          return (
            <TableCell
              key={index}
              sx={{
                position: "sticky",
                left: 0,
              }}
            >
              No data table
            </TableCell>
          );
        return <TableCell key={index}></TableCell>;
      })}
    </TableRow>
  );
};
