import { HttpClient, URL_API } from "../../network";

type TUser = {};

class UserServiceApi extends HttpClient {
  getListUser = async (params: TParams & { phone?: string }) => {
    const data = await this.instance.get(URL_API.USER.GET_LIST, {
      params,
    });
    return data;
  };

  createUser = async (dataCreate: any) => {
    const { data } = await this.instance.post(URL_API.USER.CREATE, dataCreate);
    return data;
  };

  updateUser = async (dataUpdate: any) => {
    const { data } = await this.instance.put(
      URL_API.USER.UPDATE + `${dataUpdate?._id}`,
      dataUpdate,
    );
    return data;
  };

  getListRestrictedUser = async (params: TParams) => {
    const data = await this.instance.get(URL_API.USER.GET_LIST_RESTRICTED, {
      params,
    });
    return data;
  };
}

export const UserService = new UserServiceApi();
