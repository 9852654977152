import { useMutation, useQuery } from "react-query";
import { ChargePointService } from "./charge-point.service";
import toast from "react-hot-toast";

// GET List
const useGetList = (page: number, option?: Object) => {
  return useQuery(
    ["listSesstion", page, option],
    async () => {
      const { data } = await ChargePointService.getListChargePoint({
        page,
        ...option,
      });
      return data;
    },
    { keepPreviousData: true },
  );
};

// GET Detail
const useGetDetail = (id?: string) => {
  return useQuery(
    ["getDetail", id],
    async () => {
      const { data } = await ChargePointService.getDetailChargePoint(id);
      return data;
    },
    { enabled: id ? true : false },
  );
};

const useGetListRestricted = (page: number, option?: Object) => {
  return useQuery(
    ["listUserRestricted", page, option],
    async () => {
      const { data } = await ChargePointService.getListRestrictedUser({
        page,
        ...option,
      });
      return data;
    },
    { keepPreviousData: true },
  );
};

const useEnableSubscriptionPlans = (cb?: (data: any) => void) => {
  return useMutation(
    async (createItem: any) => {
      const data = await ChargePointService.enableSubscriptionPlans(createItem);
      return data;
    },
    {
      onSuccess: (data) => {
        cb?.(data);
        if (data.ok) {
          toast.success("Create successfully!");
        } else {
          toast.error(data?.msg);
        }
      },
      onError(error, variables, context) {
        alert(error);
      },
    },
  );
};

export {
  useGetList,
  useGetDetail,
  useGetListRestricted,
  useEnableSubscriptionPlans,
};
