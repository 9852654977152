import { HttpClient, URL_API } from "../../network";

export type TLogin = {
  email: string;
  password: string;
};

class LoginServiceApi extends HttpClient {
  onLogin = async (dataUpdate: TLogin) => {
    const { data } = await this.instance.post(URL_API.AUTH.LOGIN, dataUpdate);
    return data;
  };

  getMe = async () => {
    const { data } = await this.instance.get(URL_API.AUTH.GET_ME);
    return data;
  };
}
export const LoginService = new LoginServiceApi();
