import React from "react";

export const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",
  TRANSACTION: "/transactions",
  USER: "/user",
  RESTRICTED_USER: "/user/restricted-user",
  ADD_RESTRICTED_USER: "/user/add-restricted-user",
  LOCATION: "/location",
  CHARGE_SESSION: "/charge-sessions",
  CHARGE_SESSION_DETAIL: "/charge-sessions/:id",
  PROMO_CODE: "/promo-codes",
  PROMO_CODE_DETAIL: "/promo-codes/:id",
  PROMO_CODE_ADD: "/promo-codes/create",
  PROMO_PROGRAM: "/promo-programs",
  SUBSCRIPTION_PLAN: "/subscription-plans",
  SUBSCRIPTION: "/subscriptions",
  CHARGER_POINT: "/charger-point",
  CHARGER_POINT_DETAIL: "/charger-point/:id",
  CHARGER_POINT_RESTRICTED_USER: "/charger-point/:id/restricted-user",
  IDLING_ACTIVITIES: "/idling-activities",
  CHARGER_POINT_IDLING_PENALTY: "/charger-point/:id/idling-penalty",
  CHARGER_POINT_PRICE: "/charger-point/:id/price",
  CHARGER_POINT_RESERVATION: "/charger-point/:id/reservation",
  MASTER: "",
  NOTFOUND: "*",
  RESERVATION: "/reservation",
  RESERVATION_DETAIL: "/reservation/:id",
  EVSE: "/evse",
  EVSE_DETAIL: "/evse/:id",
  INFORMATION_CATEGORY: "/information-category",
  INFORMATION: "/information",
  REPORTS: "/reports",
} as const;

//  ============  PUPLIC ROUTER  ============

const Register = React.lazy(() => import("../pages/Register"));
const Login = React.lazy(() => import("../pages/Login"));

export const publicRoutes: ComponentType[] = [
  { path: ROUTES.LOGIN, component: Login },
  { path: ROUTES.REGISTER, component: Register },
];

//  ============  PRIVATE ROUTER  ============

const ChargeSessions = React.lazy(() => import("../pages/ChargeSessions"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Location = React.lazy(() => import("../pages/LocationTable"));
const User = React.lazy(() => import("../pages/User"));
const RestrictedUser = React.lazy(() => import("../pages/User/restricted"));
const AddRestrictedUser = React.lazy(
  () => import("../pages/User/AddRestrictedUser"),
);
const Transactions = React.lazy(() => import("../pages/Transactions"));
const DetailChargeSessions = React.lazy(
  () => import("../pages/ChargeSessions/Detail"),
);
const PromoCodes = React.lazy(() => import("../pages/PromoCode"));
const PromoPrograms = React.lazy(() => import("../pages/PromoProgram"));
const SubscriptionPlans = React.lazy(
  () => import("../pages/SubscriptionPlans"),
);
const Subscriptions = React.lazy(() => import("../pages/Subscriptions"));
const ChargerPoint = React.lazy(
  () => import("../pages/ChargerPoint/TableChargerPoint"),
);
const Reservation = React.lazy(() => import("../pages/Reservation"));
// const ReservationDetail = React.lazy(() => import("../pages/Reservation"));
const Evse = React.lazy(() => import("../pages/Evse"));
const Information = React.lazy(() => import("../pages/Information"));
const InformationCategories = React.lazy(
  () => import("../pages/InformationCategory"),
);

const ChargePointPrice = React.lazy(
  () => import("../pages/ChargerPoint/Price"),
);
const ChargerPointRestrictedUser = React.lazy(
  () => import("../pages/ChargerPoint/RestrictedUser"),
);
const ChargerPointIdling = React.lazy(
  () => import("../pages/ChargerPoint/IdlingPenalty"),
);
const IdlingActivities = React.lazy(
  () => import("../pages/ChargeSessions/IdlingActivities"),
);

const Reports = React.lazy(() => import("../pages/Reports"));

const ChargerPointDetail = React.lazy(
  () => import("../pages/ChargerPoint/Detail"),
);
const ChargerPointReversation = React.lazy(
  () => import("../pages/ChargerPoint/Reservation"),
);

const EvseDetail = React.lazy(() => import("../pages/Evse/Detail"));

//component

const Icon = React.lazy(() => import("../pages/component/Icon"));

export const PrivateRouter = [
  { path: ROUTES.DASHBOARD, component: Dashboard },
  { path: ROUTES.MASTER, component: Dashboard },
  { path: ROUTES.CHARGE_SESSION, component: ChargeSessions },
  { path: ROUTES.TRANSACTION, component: Transactions },
  { path: ROUTES.LOCATION, component: Location },
  { path: ROUTES.USER, component: User },
  { path: ROUTES.RESTRICTED_USER, component: RestrictedUser },
  { path: ROUTES.ADD_RESTRICTED_USER, component: AddRestrictedUser },
  { path: ROUTES.PROMO_PROGRAM, component: PromoPrograms },
  { path: ROUTES.SUBSCRIPTION_PLAN, component: SubscriptionPlans },
  { path: ROUTES.SUBSCRIPTION, component: Subscriptions },
  { path: ROUTES.CHARGE_SESSION_DETAIL, component: DetailChargeSessions },
  { path: ROUTES.PROMO_CODE, component: PromoCodes },
  { path: ROUTES.CHARGER_POINT, component: ChargerPoint },
  { path: ROUTES.CHARGER_POINT_DETAIL, component: ChargerPointDetail },
  {
    path: ROUTES.CHARGER_POINT_RESTRICTED_USER,
    component: ChargerPointRestrictedUser,
  },
  { path: ROUTES.RESERVATION, component: Reservation },
  { path: ROUTES.EVSE, component: Evse },
  { path: ROUTES.EVSE_DETAIL, component: EvseDetail },
  { path: ROUTES.INFORMATION_CATEGORY, component: InformationCategories },
  { path: ROUTES.INFORMATION, component: Information },
  { path: ROUTES.CHARGER_POINT_PRICE, component: ChargePointPrice },
  { path: ROUTES.CHARGER_POINT_IDLING_PENALTY, component: ChargerPointIdling },
  { path: ROUTES.IDLING_ACTIVITIES, component: IdlingActivities },
  { path: ROUTES.REPORTS, component: Reports },
  {
    path: ROUTES.CHARGER_POINT_RESERVATION,
    component: ChargerPointReversation,
  },
  { path: "/component/icon", component: Icon },
];
